<template>
  <step-activity-layout :title="$t('module1.part1.activity.title')" :activity-finished="isGameFinished" :activity-started="isGameStarted">
    <template v-slot:intro>
      <activity-intro @start="isGameStarted = true"
        :title="$t('module1.part1.activity.intro.title')"
        :rules-content="$t('module1.part1.activity.intro.rules')"
        :rules-image="require('@/assets/module1/part1/activity-intro-illu.svg')"
      >
      </activity-intro>
    </template>
    <template v-slot:content>
      <chat-activity v-if="!isGameFinished" @finished="finishedActivity"></chat-activity>
      <activity-result-block v-else @replay-activity="replayActivity" :is-custom-content="true">
        <template v-slot:content>
          <img class="result-image" :src="require('@/assets/module1/part1/activity-intro-illu.svg')">
        </template>
      </activity-result-block>
    </template>
  </step-activity-layout>
</template>

<script>
import StepActivityLayout from '@/components/training/StepActivityLayout'
import ChatActivity from '@/components/training/module1/part1/ChatActivity'
import useTrainingUtils from '@/utils/useTrainingUtils'
import { computed } from 'vue'
import ActivityResultBlock from '@/components/training/ActivityResultBlock'
import useActivityUtils from '@/utils/useActivityUtils'
import ActivityIntro from '@/components/training/ActivityIntro'

export default {
  name: 'Module1Step1Activity',
  components: { ChatActivity, StepActivityLayout, ActivityResultBlock, ActivityIntro },
  setup () {
    const { currentPartDatas } = useTrainingUtils()
    const { isGameFinished, replayActivity, isGameStarted, onActivityFinished } = useActivityUtils()

    const userLabel = computed(() => currentPartDatas.value?.userLabel)

    return { isGameFinished, userLabel, onActivityFinished, isGameStarted, replayActivity }
  },
  methods: {
    finishedActivity () {
      this.onActivityFinished()
    }
  }
}
</script>

<style lang="scss" scoped>
.result-image {
  width: 100%;
}
</style>
