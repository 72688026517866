<template>
  <div class="chat-activity">
    <div class="chat-game">
      <div class="chat-box">
        <div class="messages-wrapper" ref="chatbox">
          <div v-for="message in messages"
            :key="message.name"
            class="message" :class="{ [message.type]: !!message.type, [message.owner]: true }">
            <p v-html="message.label"></p>
          </div>
          <div class="recipient-typing" v-show="recipientTyping">
            <img :src="require('@/assets/dot-loading.svg')" :alt="$t('global.imageAlts.loadingIcon')">
          </div>
        </div>
        <div class="answers-box" v-if="!isGameFinished">
          <div v-if="!feedbackShown" class="answers-wrapper" :class="{ hidden: recipientTyping, 'answer-animating': answerAnimating }">
            <p class="label">{{ $t('module1.part1.activity.label') }}</p>
            <div v-for="answer in currentAnswers"
              :key="answer.name"
              @click="selectAnswer(answer)"
              class="answer"
              :class="{ selected: selectedAnswer?.name === answer.name, [answer.type]: true }"
            >
              <p v-html="answer.label"></p>
            </div>
          </div>
          <div v-if="feedbackShown" class="feedback-wrapper">
            <p class="label">{{ $t('module1.part1.activity.feedbackLabel') }}</p>
            <p class="feedback">
              {{ selectedAnswer.feedback }}
            </p>
          </div>
          <div class="answers-buttons">
            <app-button-layout @click="submitAnswer" v-if="answerSelected && !answerSubmited">{{ $t('global.navigation.validate') }}</app-button-layout>
            <app-button-layout @click="goToNextQuestion" v-else-if="answerSubmited && feedbackShown && !answerAnimating">{{ $t('global.navigation.resume') }}</app-button-layout>
          </div>
        </div>
      </div>
    </div>
    <div class="game-bottom">
      <div :class="{ 'app-decoration-block': mq.tabletPlus }">
        <p :class="{ success: isGameFinished }">
          {{ isGameFinished ? $t('module1.part1.activity.success') : $t('module1.part1.activity.help') }}
        </p>
        <app-button-layout :disabled="!isGameFinished" @click="finishActivity">
          {{ $t('global.navigation.terminate') }}
        </app-button-layout>
      </div>
    </div>
  </div>
</template>

<script>
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import { nextTick } from 'vue'

export default {
  name: 'ChatActivity',
  components: { AppButtonLayout },
  emits: ['finished'],
  inject: ['mq'],
  data () {
    return {
      messages: [],
      initialMessages: [
        {
          name: 'message1',
          label: this.$t('module1.part1.activity.recipientAnswers.answer1'),
          owner: 'recipient'
        },
        {
          name: 'message2',
          label: this.$t('module1.part1.activity.recipientAnswers.answer2'),
          owner: 'recipient'
        },
        {
          name: 'message3',
          label: this.$t('module1.part1.activity.recipientAnswers.answer3'),
          owner: 'recipient'
        }
      ],
      steps: [
        {
          name: 'step1',
          answers: [
            {
              name: 'answer1',
              label: this.$t('module1.part1.activity.userAnswers.answer1'),
              type: 'wrong',
              feedback: this.$t('module1.part1.activity.feedbacks.feedback1')
            },
            {
              name: 'answer2',
              label: this.$t('module1.part1.activity.userAnswers.answer2'),
              type: 'right',
              feedback: this.$t('module1.part1.activity.feedbacks.feedback2')
            }
          ],
          messages: [
            {
              name: 'message4',
              label: this.$t('module1.part1.activity.recipientAnswers.answer4')
            }
          ]
        },
        {
          name: 'step2',
          answers: [
            {
              name: 'answer3',
              label: this.$t('module1.part1.activity.userAnswers.answer3'),
              type: 'wrong',
              feedback: this.$t('module1.part1.activity.feedbacks.feedback3')
            },
            {
              name: 'answer4',
              label: this.$t('module1.part1.activity.userAnswers.answer4'),
              type: 'right',
              feedback: this.$t('module1.part1.activity.feedbacks.feedback4')
            }
          ],
          messages: [
            {
              name: 'message5',
              label: this.$t('module1.part1.activity.recipientAnswers.answer5')
            }
          ]
        },
        {
          name: 'step3',
          answers: [
            {
              name: 'answer5',
              label: this.$t('module1.part1.activity.userAnswers.answer5'),
              type: 'right',
              feedback: this.$t('module1.part1.activity.feedbacks.feedback5')
            },
            {
              name: 'answer6',
              label: this.$t('module1.part1.activity.userAnswers.answer6'),
              type: 'wrong',
              feedback: this.$t('module1.part1.activity.feedbacks.feedback6')
            }
          ],
          messages: [
            {
              name: 'message6',
              label: this.$t('module1.part1.activity.recipientAnswers.answer7'),
              type: 'wrong'
            },
            {
              name: 'message7',
              label: this.$t('module1.part1.activity.recipientAnswers.answer8')
            }
          ]
        },
        {
          name: 'step4',
          answers: [
            {
              name: 'answer7',
              label: this.$t('module1.part1.activity.userAnswers.answer7'),
              type: 'wrong',
              feedback: this.$t('module1.part1.activity.feedbacks.feedback7')
            },
            {
              name: 'answer8',
              label: this.$t('module1.part1.activity.userAnswers.answer8'),
              type: 'right',
              feedback: this.$t('module1.part1.activity.feedbacks.feedback8')
            }
          ],
          messages: [
            {
              name: 'message9',
              label: this.$t('module1.part1.activity.recipientAnswers.answer9'),
              type: 'wrong'
            },
            {
              name: 'message10',
              label: this.$t('module1.part1.activity.recipientAnswers.answer10'),
              type: 'right'
            },
            {
              name: 'message11',
              label: this.$t('module1.part1.activity.recipientAnswers.answer11')
            }
          ]
        },
        {
          name: 'step5',
          answers: [
            {
              name: 'answer10',
              label: this.$t('module1.part1.activity.userAnswers.answer9'),
              type: 'right',
              feedback: this.$t('module1.part1.activity.feedbacks.feedback9')
            },
            {
              name: 'answer11',
              label: this.$t('module1.part1.activity.userAnswers.answer10'),
              type: 'wrong',
              feedback: this.$t('module1.part1.activity.feedbacks.feedback10')
            }
          ],
          messages: [
            {
              name: 'message12',
              label: this.$t('module1.part1.activity.recipientAnswers.answer12')
            }
          ]
        },
        {
          name: 'step6',
          answers: [
            {
              name: 'answer12',
              label: this.$t('module1.part1.activity.userAnswers.answer11'),
              type: 'wrong',
              feedback: this.$t('module1.part1.activity.feedbacks.feedback11')
            },
            {
              name: 'answer13',
              label: this.$t('module1.part1.activity.userAnswers.answer12'),
              type: 'right'
            }
          ],
          messages: [
            {
              name: 'message13',
              label: this.$t('module1.part1.activity.recipientAnswers.answer13'),
              type: 'wrong'
            },
            {
              name: 'message14',
              label: this.$t('module1.part1.activity.recipientAnswers.answer14'),
              type: 'right'
            }
          ]
        }
      ],
      currentStepIndex: 0,
      recipientTyping: false,
      isGameFinished: false,
      selectedAnswer: null,
      answerSelected: false,
      answerSubmited: false,
      feedbackShown: false,
      answerAnimating: false,
      answerAnimationTimeout: null,
      showAnswersTimeout: null
    }
  },
  async mounted () {
    const typingMessages = []
    for (const message of this.initialMessages) {
      typingMessages.push(this.recipientAnswer(message))
    }
    await Promise.all(typingMessages)
    await this.showUserAnswers()
  },
  computed: {
    currentStep () {
      return this.steps[this.currentStepIndex]
    },
    currentAnswers () {
      return this.currentStep.answers
    },
    isLastStep () {
      return this.currentStepIndex >= this.steps.length - 1
    }
  },
  methods: {
    async showUserAnswers () {
      await new Promise(resolve => setTimeout(() => {
        this.recipientTyping = false
        resolve()
      }, 500))
    },
    async stepRecipientAnswer (messages) {
      for (const message of messages) {
        await this.recipientAnswer(message)
      }
      await this.showUserAnswers()
    },
    async pushMessage (message) {
      this.messages.push(message)
      await nextTick()
      this.scrollToChatboxBottom()
    },
    async recipientAnswer (message) {
      this.recipientTyping = true
      await nextTick()
      this.scrollToChatboxBottom()
      return new Promise(resolve => setTimeout(() => {
        this.pushMessage({
          owner: 'recipient',
          ...message
        })
        resolve()
      }, message.label.length * 20))
    },
    selectAnswer (answer) {
      this.selectedAnswer = answer
      this.answerSelected = true
    },
    async submitAnswer () {
      await this.pushMessage({
        owner: 'user',
        ...this.selectedAnswer
      })
      this.answerSubmited = true
      this.answerAnimating = true
      this.answerAnimationTimeout = setTimeout(() => {
        this.answerAnimating = false
        this.showFeedback()
      }, 2500)
    },
    async showFeedback () {
      clearTimeout(this.answerAnimationTimeout)
      if (this.selectedAnswer.feedback === undefined) {
        await this.goToNextQuestion()
        return
      }
      this.feedbackShown = true
    },
    async goToNextQuestion () {
      this.feedbackShown = false
      this.answerSelected = false
      this.answerSubmited = false
      await this.stepRecipientAnswer(
        this.currentStep.messages
          .filter(message => message.type === this.selectedAnswer.type || message.type === undefined)
      )
      this.selectedAnswer = null
      if (this.isLastStep) {
        this.isGameFinished = true
        return
      }
      this.currentStepIndex++
    },
    scrollToChatboxBottom () {
      if (this.$refs.chatbox) {
        this.$refs.chatbox.scroll({
          top: this.$refs.chatbox.scrollHeight,
          behavior: 'smooth'
        })
      }
    },
    finishActivity () {
      if (this.isGameFinished) {
        this.$emit('finished')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-activity {
  .chat-game {
    padding: $space-s;
    .chat-box {
      padding: $space-sm $space-s;
      background-color: #fff;
      border-radius: $radius;
      display: flex;
      flex-direction: column;
      .messages-wrapper {
        display: flex;
        flex-direction: column;
        height: 220px;
        overflow-y: auto;
        scroll-behavior: smooth;
        .message + .message {
          margin-top: $space-xs;
        }
        .message {
          align-self: flex-start;
          border-radius: 120px;
          background-color: $c-background;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: $fz-s;
          font-family: $ff-text;
          font-weight: $fw-m;
          padding: $space-sm $space-ml;
          max-width: 90%;
          &.user {
            align-self: flex-end;
            color: #fff;
            background-color: $c-secondary;
          }
        }
        .recipient-typing {
          padding: $space-s $space-sm;
          img {
            width: 25px;
          }
        }
      }
      .answers-box {
        margin-top: $space-s;
        padding: $space-s;
        .label {
          margin-bottom: $space-s;
          font-size: $fz-xs;
          font-weight: $fw-l;
          text-transform: uppercase;
        }
        .answers-wrapper {
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          opacity: 1;
          transform-origin: top;
          &.hidden {
            opacity: 0;
            pointer-events: none;
            transform: scaleY(0);
          }
          .answer + .answer {
            margin-top: $space-xs;
          }
          .answer {
            cursor: pointer;
            padding: $space-sm $space-m;
            box-shadow: 0 2px 1px rgba(181, 186, 190, 0.4);
            border-radius: $radius;
            border: 1px solid $c-border;
            text-align: center;
            color: $c-secondary;
            font-size: $fz-s;
            font-weight: $fw-m;
            transition: background-color ease-out 0.3s;
            &.selected {
              border: 3px solid $c-secondary;
              font-weight: $fw-l;
            }
            &:hover {
              background-color: $c-background;
            }
          }
          &.answer-animating {
            .answer {
              &:hover {
                background-color: #fff;
              }
            }
            .answer.right {
              animation-name: answerAnimation;
              animation-duration: 0.3s;
              animation-iteration-count: 3;
              border-width: 3px;
              border-color: $c-primary;
              color: $c-primary;
              font-weight: $fw-l;
            }
            .answer.wrong {
              box-shadow: none;
              font-weight: $fw-m;
              border: none;
              background-color: $c-background;
              color: $c-text-light;
            }
          }
        }
        .feedback-wrapper {
          .feedback {
            border-radius: $radius;
            background-color: $c-background;
            padding: $space-m $space-ml;
            color: $c-text-dark;
            font-size: $fz-s;
            font-weight: $fw-m;
          }
        }
        .answers-buttons {
          .app-button-layout {
            width: 100%;
            margin-top: $space-sm;
          }
        }
      }
    }
  }
  .game-bottom {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 0 $radius-xl $radius-xl;
    padding: $space-m $space-ml $space-ml $space-ml;
    .help.success {
      color: $c-primary;
    }
    .app-button-layout {
      margin: $space-m auto 0 auto;
      width: 100%;
    }
  }
}

@keyframes answerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: $bp-tablet) {
  .chat-activity {
    display: grid;
    gap: $space-s;
    grid-template-columns: repeat(9, 1fr);
    .chat-game {
      grid-column-start: 1;
      grid-column-end: 6;
      .chat-box {
        padding: $space-sm $space-xl;
      }
    }
    .game-bottom {
      background-color: transparent;
      padding: $space-s 0 0 0;
      grid-column-start: 6;
      grid-column-end: 10;
    }
  }
}
</style>
